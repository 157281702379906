var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    {
      attrs: { session: false },
      scopedSlots: _vm._u([
        {
          key: "actionbar",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-wrap justify-content-between flex-grow-1"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-grow-1 d-md-flex justify-content-md-start"
                    },
                    [
                      _c("div", { staticStyle: { margin: "10px auto" } }, [
                        _c("h6", [
                          _vm._v(_vm._s(_vm.$t("lang.texto_camara.msg")))
                        ])
                      ])
                    ]
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "viewport" }, [
        _c("div", {
          staticClass: "embed-camera",
          domProps: { innerHTML: _vm._s(_vm.camera.embed_script) }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }