<template>
  <div class="login container reset-password">
    <b-row align-v="center" align-h="center" class="h-100">
      <b-col md="8" lg="6" align-self="center">
        <b-card class="login-form">
          <b-card-body class="position-relative">
            <button
              type="button"
              class="position-absolute btn-circle btn-back-guest"
              @click="$router.push({ name: 'login' })"
            >
              <i class="tmlps-icn-back h2"></i>
            </button>

            <div class="d-flex align-items-center justify-content-center">
              <Logo :image-url="config.logo" />

              <h1 class="title" v-if="config.name">{{ config.name }}</h1>
              <h1 class="title" v-else>Timelapse <br />México</h1>
            </div>

            <h4 class="text-center mt-4">Establece una nueva contraseña</h4>
            <p class="text-left my-4">
              Ingresa una nueva contraseña para poder ingresar <br />
              nuevamente a la plataforma
            </p>

            <div>
              <form @submit.prevent="resetPassword">
                <div class="mt-3">
                  <EmailInput v-model="form.email" :error="errors.first('email')" />
                </div>

                <div class="mt-4">
                  <PasswordInput v-model="form.password" :error="errors.first('password')" />
                </div>

                <div class="mt-4">
                  <PasswordInput
                    v-model="form.password_confirm"
                    :error="errors.first('password')"
                  />
                </div>

                <b-alert class="mt-4" :show="errors.has('reset-password')" variant="danger">{{
                  errors.first('reset-password')
                }}</b-alert>

                <div class="text-center mt-4">
                  <button class="btn btn-primary px-5" :disabled="loading">Establecer</button>
                </div>
              </form>
            </div>
          </b-card-body>
        </b-card>

        <div class="privacy py-4 fixed-bottom">
          <div class="text-center">Todos los derechos reservados Timelapse México {{anioActual}}</div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import store from '@/store';
import Errors from '@/util/Errors';
import Logo from '@/components/Logo';
import EmailInput from '@/components/EmailInput';
import PasswordInput from '@/components/PasswordInput';

export default {
  components: {
    Logo,
    EmailInput,
    PasswordInput,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        password_confirm: '',
      },
      loading: false,
      errors: new Errors(),
      anioActual: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapState('domain', ['config']),
  },
  methods: {
    resetPassword() {
      this.loading = true;
      this.errors.clear();

      store
        .dispatch('auth/resetPassword', {
          email: this.form.email,
          password: this.form.password,
          password_confirmation: this.form.password_confirm,
          token: this.$route.query.token,
        })
        .then(() => {
          this.$router.replace({ name: 'login' }).catch(() => {});
        })
        .catch((error) => {
          if (error.response && error.response.data.errors) {
            this.errors.capture(error.response.data.errors);
          }
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    document.body.classList.add('login-page');
  },
  destroyed() {
    document.body.classList.remove('login-page');
  },

  async beforeRouteEnter(from, to, next) {
    await store.dispatch('domain/fetchDomain', document.location.host);
    // document.documentElement.style.setProperty('--primary', domain.color);
    next();
  },
};
</script>

<style lang="sass" scoped>
.login
  height: 100vh

.privacy
  color: white
  font-size: 0.875rem

.title
  text-transform: uppercase
  font-size: 1.75rem
  margin-left: 0.5rem
</style>
