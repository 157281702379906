var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login container reset-password" },
    [
      _c(
        "b-row",
        {
          staticClass: "h-100",
          attrs: { "align-v": "center", "align-h": "center" }
        },
        [
          _c(
            "b-col",
            { attrs: { md: "8", lg: "6", "align-self": "center" } },
            [
              _c(
                "b-card",
                { staticClass: "login-form" },
                [
                  _c("b-card-body", { staticClass: "position-relative" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "position-absolute btn-circle btn-back-guest",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({ name: "login" })
                          }
                        }
                      },
                      [_c("i", { staticClass: "tmlps-icn-back h2" })]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center"
                      },
                      [
                        _c("Logo", { attrs: { "image-url": _vm.config.logo } }),
                        _vm.config.name
                          ? _c("h1", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.config.name))
                            ])
                          : _c("h1", { staticClass: "title" }, [
                              _vm._v("Timelapse "),
                              _c("br"),
                              _vm._v("México")
                            ])
                      ],
                      1
                    ),
                    _c("h4", { staticClass: "text-center mt-4" }, [
                      _vm._v("Establece una nueva contraseña")
                    ]),
                    _c("p", { staticClass: "text-left my-4" }, [
                      _vm._v(
                        " Ingresa una nueva contraseña para poder ingresar "
                      ),
                      _c("br"),
                      _vm._v(" nuevamente a la plataforma ")
                    ]),
                    _c("div", [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.resetPassword($event)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mt-3" },
                            [
                              _c("EmailInput", {
                                attrs: { error: _vm.errors.first("email") },
                                model: {
                                  value: _vm.form.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-4" },
                            [
                              _c("PasswordInput", {
                                attrs: { error: _vm.errors.first("password") },
                                model: {
                                  value: _vm.form.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-4" },
                            [
                              _c("PasswordInput", {
                                attrs: { error: _vm.errors.first("password") },
                                model: {
                                  value: _vm.form.password_confirm,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "password_confirm", $$v)
                                  },
                                  expression: "form.password_confirm"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-alert",
                            {
                              staticClass: "mt-4",
                              attrs: {
                                show: _vm.errors.has("reset-password"),
                                variant: "danger"
                              }
                            },
                            [_vm._v(_vm._s(_vm.errors.first("reset-password")))]
                          ),
                          _c("div", { staticClass: "text-center mt-4" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary px-5",
                                attrs: { disabled: _vm.loading }
                              },
                              [_vm._v("Establecer")]
                            )
                          ])
                        ],
                        1
                      )
                    ])
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "privacy py-4 fixed-bottom" }, [
                _c("div", { staticClass: "text-center" }, [
                  _vm._v(
                    "Todos los derechos reservados Timelapse México " +
                      _vm._s(_vm.anioActual)
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }