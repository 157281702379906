<template>
<AppLayout :session="false">
    <div class="viewport">
      <div class="embed-camera" v-html="camera.embed_script"></div>

    </div>
    <template v-slot:actionbar>
      <div class="d-flex flex-wrap justify-content-between flex-grow-1">
        <div class="flex-grow-1 d-md-flex justify-content-md-start">
          <div  style="margin:10px auto">
              <h6>{{$t('lang.texto_camara.msg')}}</h6>
          </div>
        </div>
      </div>
    </template>
</AppLayout>
</template>

<script>
import store from '@/store';

export default {
  name: 'CameraLibre',
  components: {
  },
  data() {
    return {
      camera:{},
    };
  },
  computed: {
  },
  created() {
    this.init();
  },
  methods: {
    async init(){
        const idCamera = this.$route.params.id;
        console.log("idCamera",idCamera)
        this.camera = await store.dispatch('camera/fetchCameraLibre', idCamera);
        //console.log("CAMARA: ",this.camera );
    },
  },
};
</script>

<style lang="sass">
.viewport
  height: 100%
  width: 100%
  position: relative
  overflow: hidden
  background: #242424

.embed-camera
  height: 100%
  width: 100%
  position: relative

  > iframe
    position: absolute !important
    top: 0
    right: 0
    bottom: 0
    left: 0
    width: 100% !important
    height: 100% !important
</style>
